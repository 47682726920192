<template>
  <router-view />
</template>

<script>

export default {
  name: 'MainLayout',
  components: {},
};
</script>

<style lang="scss" scoped>

</style>
